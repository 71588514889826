frappe.provide("frappe.views");

(function() {
    // Save the original setup_view_menu method
    const original_setup_view_menu = frappe.views.BaseList.prototype.setup_view_menu;

    // Override the setup_view_menu method
    frappe.views.BaseList.prototype.setup_view_menu = function() {
        // Call the original method
        original_setup_view_menu.call(this);

        // Add Pane View option
        if (!this.views_menu) return;

        const pane_view_option = `
            <li data-view="PaneView">
                <a class="grey-link dropdown-item" href="#" onclick="return false;">
                    <span class="menu-item-icon">
                        <svg class="icon icon-sm" aria-hidden="true">
                            <use href="#icon-dashboard"></use>
                        </svg>
                    </span>
                    <span class="menu-item-label" data-label="PaneView">
                        <span>
                            <span class="alt-underline">P</span>ane View
                        </span>
                    </span>
                </a>
            </li>`;
        $(pane_view_option).appendTo(this.views_menu);

        this.views_menu.find('[data-view="PaneView"]').on('click', function() {
            const doctype = frappe.get_route()[1];
            const route = `/app/pane_view/${doctype}/pane_view`;
            frappe.set_route(route);
        });
    };
})();
